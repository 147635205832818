import { isArray, isString } from '@penbox-io/stdlib'
import { IStepDefinitionParser } from '../core/definition'
import { parseAssignedTo, parseNotifications } from '../core/definitionHelper'
import { ValueGetterResponse, WorkflowStep, WorkflowStepNotificationBaseState } from '../core/step'

/**
 * Email Workflow Step
 *
 * This step sends an email to the recipient.
 *
 * Configuration sample:
 * {
 *  key: 'email',
 *  type: 'email',
 *  properties: {
 *    subject: 'test',
 *    body: 'test',
 *    to: 'test@test.com',
 *  },
 * }
 *
 */

type WorkflowStepEmailNotificationState = WorkflowStepNotificationBaseState & {
  email?: {
    subject?: string | null
    body?: string | null
    from?: string | null
    to?: string | null
    cc?: string[] | null
    bcc?: string[] | null
    reply_to?: string | null
    status?: string | null
    statusDate?: Date | null
  } | null
}

export type EmailWorkflowStep = WorkflowStep & {
  properties: {
    subject?: string
    body?: string
    from?: string
    cc?: string[]
    bcc?: string[]
    reply_to?: string
  }
  state: WorkflowStepEmailNotificationState | null
}

class Email implements IStepDefinitionParser<EmailWorkflowStep> {
  parse(step: any, value?: ValueGetterResponse | null): EmailWorkflowStep {
    const key = isString(step.key) ? step.key : ''
    return {
      key,
      type: 'email',
      title: isString(step.title) ? step.title : key,
      assigned_to: parseAssignedTo(step.assigned_to),
      properties: {
        subject: isString(step.properties?.subject) ? step.properties.subject : undefined,
        body: isString(step.properties?.body) ? step.properties.body : undefined,
        from: isString(step.properties?.from) ? step.properties.from : undefined,
        cc: isArray(step.properties?.cc) ? step.properties.cc : undefined,
        bcc: isArray(step.properties?.bcc) ? step.properties.bcc : undefined,
        reply_to: isString(step.properties?.reply_to) ? step.properties.reply_to : undefined,
      },
      state: value?.notification
        ? {
            id: value.notification.id,
            type: 'notification',
            email: value.notification.email,
            status: value.notification.status,
            error: value.notification.error,
            sentAt: value.notification.sentAt,
            scheduled_at: value.notification.scheduled_at,
          }
        : null,
    }
  }
}

export default new Email()
